import React, { Component } from 'react'
import { Container, Row, Col } from 'reactstrap'

import { withTranslation } from 'react-i18next'
import { LazyLoadImage } from 'react-lazy-load-image-component'
import 'react-multi-carousel/lib/styles.css'
import LazyLoad from 'react-lazy-load'

import NahibuHelmet from '../components/nahibu-helmet'
import alimentationPersonnalisee from '../images/Recommandations nutritionnelles SHIDO -Nutrition personnalisee (1).png'

import bpiFrance from '../images/bpi-france.png'
import valorial from '../images/valorial.png'
import biotechEtSante from '../images/biotech-et-sante-bretagne.png'
import lePoool from '../images/lepoool.png'
import frenchTech from '../images/french-tech.png'
import regionBretagne from '../images/region-bretagne.png'
import i18n from 'i18next'

class Team extends Component {
  constructor(props) {
    super(props)
    this.state = {
      tabId: 'tabId1',
    }
  }

  render() {
    const t = this.props.t
    const language = i18n.language ? ( i18n.language.startsWith("fr") ? "fr" : i18n.language.startsWith("en") ? "en" : 'es' ) : undefined
    const langSubPath = language === "en" ? "/en" : language === "es" ? "/es" : ""

    return (
      <>
        <NahibuHelmet
        canonicalUrl = {'https://nahibu.me'+langSubPath+'/'+t('canonical-url|qui-sommes-nous')+'/'}
        title={'Nahibu | ' + t('team|Equipe & Mission')}
        description={t('team|Découvrez l’histoire et la vision de l’équipe Nahibu pour améliorer le bien-être collectif au travers de l’analyse du microbiote intestinal et de la nutrition personnalisée.')}
        />
        {/* Qui sommes nous ?  */}
        <Container fluid className='full-page-center bg-image bg-center bg-image-team'>
          <Container>
            <Col md={9} className='center'>
              <h1 className='main-title'>{t('team|Qui sommes nous ?')}</h1>
              <h2 className='white'>{t('team|Nahibu, une équipe experte du microbiote intestinal.')}</h2>
            </Col>
          </Container>
        </Container>

        {/* L'histoire de nahibu */}
        <div className=''>
          <Container className='my-5 py-5'>
            <h2 className='mb-5 second-title dark-blue small-underline'>{t("team|L'histoire de Nahibu")}</h2>
            <Row>
              <Col className='margin-auto' md={6}>
                <p className=''>{t('team|Convaincus depuis des années que le microbiote est un acteur majeur de notre santé, Chrystèle et Pierre Cressard ont fondé Nahibu en 2019.')}</p>
                <p className=''>{t('team|Leur objectif est d’améliorer la santé par l’alimentation.')}</p>
                <p className=''>{t('team|Grâce à Nahibu, ils veulent permettre au plus grand nombre de découvrir cet organe encore méconnu qu’est le microbiote intestinal et d’adopter une alimentation adaptée à leurs besoins.')}</p>
              </Col>
              <Col md={6} className=' py-3 center'>
                <div className='mb-5 video-responsive'>
                  <LazyLoad debounce={false}>
                    <iframe
                      title='Découvrez les coulisses de Nahibu ainsi que le portrait de son fondateur'
                      style={{ borderRadius: '1em' }}
                      src='https://www.youtube.com/embed/qoSg5GZYJl4'
                      frameBorder='0'
                      allow='accelerometer; autoplay; clipboard-write; encrypted-media; gyroscope; picture-in-picture'
                      allowFullScreen
                    ></iframe>
                  </LazyLoad>
                </div>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Notre vision */}
        <div className='gradiant-nah'>
          <Container className='my-5 py-5'>
            <Row>
              <Col md={6}></Col>
              <Col md={6}>
                <h2 className='mb-5 second-title white small-underline'>{t('team|Notre vision')}</h2>
              </Col>
            </Row>

            <Row className='flex-column-reverse flex-lg-row'>
              <Col md={6} className='center'>
                <LazyLoadImage className='center my-5' alt={t('team|Alimentation et la nutrition personnalisée de Shido et Nahibu')} src={alimentationPersonnalisee} width='80%' />
              </Col>
              <Col className='margin-auto white' md={6}>
                <p className=''>
                  {t(
                    'team|Le microbiote est un écosystème avec lequel nous avons co-évolué depuis des millions d’années. Il participe à de nombreuses fonctions, à nous de prendre soin de lui avec une alimentation adaptée ! Découvrir la composition de son microbiote est un grand pas vers une meilleure santé, grâce à une alimentation personnalisée à son potentiel fonctionnel.'
                  )}
                </p>
                <p className=''>{t('team|Améliorer la santé et le bien-être par l’alimentation est la mission de Nahibu. Nous souhaitons devenir l’acteur de l’alimentation de demain et promouvoir un mode de vie plus sain, spécifique aux besoins de chacun.')}</p>
              </Col>
            </Row>
          </Container>
        </div>

        {/* Nos partenaires */}
        <Container fluid className=''>
          <Container className='my-5 py-5'>
            <h2 className=' center mb-5 second-title dark-blue'>{t('team|Nos partenaires')}</h2>
            <p className='center bold'>{t('team|Ils nous font confiance et nous soutiennent depuis le début de l’aventure Nahibu.')}</p>
            <p className='center'>{t('team|Nous nous appuyons sur l’expertise et le savoir–faire de nos partenaires pour créer un produit qui répond à toutes les exigences.')}</p>

            <Row className='center mt-5 '>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://www.bpifrance.fr/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|Bpifrance partenaire Nahibu')} width='50%' src={bpiFrance} />
                </a>
              </Col>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://www.biotech-sante-bretagne.fr/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|Biotech Et Santé partenaire Nahibu')} width='50%' src={biotechEtSante} />
                </a>
              </Col>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://www.pole-valorial.fr/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|Valorial partenaire Nahibu')} width='50%' src={valorial} />
                </a>
              </Col>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://www.bretagne.bzh/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|Région bretagne partenaire Nahibu' )}width='50%' src={regionBretagne} />
                </a>
              </Col>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://lafrenchtech.com/fr/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|French Tech partenaire Nahibu')} width='50%' src={frenchTech} />
                </a>
              </Col>
              <Col md={4} xs={6} className='margin-auto'>
                <a href='https://lepoool.tech/' target="_blank" rel="noreferrer">
                  <LazyLoadImage className='m-3' alt={t('team|Le Poool partenaire Nahibu')} width='50%' src={lePoool} />
                </a>
              </Col>
            </Row>
          </Container>
        </Container>

        {/* Achetez */}
        {/* <Suspense fallback={<div></div>}>
        <BuyDiv />
        </Suspense>*/}
      </>
    )
  }
}

export default withTranslation()(Team)
