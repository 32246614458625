import i18n from 'i18next'
import { initReactI18next } from 'react-i18next'
import Backend from 'i18next-http-backend'
import LanguageDetector from 'i18next-browser-languagedetector'

const detectionOption = {
  order: ['path', 'cookie'],
  lookupCookie: 'i18next',
  caches: ['cookie'],
  cookieOptions: { path: '/', sameSite: 'strict' },
}

i18n.use(Backend)
    .use(LanguageDetector)
    .use(initReactI18next)
    .init({
      ns: [
        "404",
        "account",
        "avis",
        "affilies",
        "affiliates-account",
        "baiotiku",
        "buy",
        "cart",
        "committee",
        "consent",
        "consulting",
        "contact",
        "cookie",
        "expertise",
        "faq",
        "footer",
        "home",
        "kit",
        "login",
        "microbiota",
        "navbar",
        "order",
        "orders",
        "path",
        "pudp",
        "shido",
        "team",
        "translation",
        "cookie-consent",
        "product",
        "nos-produits",
        "rechercheDeveloppement",
        "country"
      ],
      defaultLanguage: 'fr',
      fallbackLng: 'fr',
      otherLanguages: ['en', 'es'],
      whitelist: ['en', 'fr', 'es'],
      preload: ['en', 'fr', 'es'],
      detection: detectionOption,
      debug: false,
      interpolation: {
        escapeValue: false,
      },
      react: {
        useSuspense: false
      },
      keySeparator: false,
      nsSeparator: "|",
    })

export default i18n
