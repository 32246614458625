import Cookies from 'universal-cookie'
import ReactGA from 'react-ga'
//import baiotiku from '../routes/baiotiku'

export default class CartClass {

  constructor(cart=null, store=null) {
    this.items = cart ? cart.items : []
    this.coupons = cart ? cart.coupons : []
    this.timestamp = Date.now()
    this.shippingAndTaxes = cart ? cart.shippingAndTaxes : {}
    this.store = store
  }

  clearCart () {
    this.items = []
    this.coupons = []
    this.timestamp = Date.now()
  }

  isCartEmpty () {
    return this.items.length === 0 ? true : false
  }

  updateShippingAndTaxes(iso, shippingAndTaxes) {
    shippingAndTaxes.countries.forEach(country => {
      if (country.iso === iso) {
        this.shippingAndTaxes = country
      }
    })
  }

  tax (value, rate) {
    return value + value * rate
  }

  getShipping (config) {

    let productSkus=[]
    Object.entries(config.products).map( ([key, product]) => productSkus=productSkus.concat(product.skus) )
    const myItems = []

    for(let j = 0; j < this.items.length; j++){
      myItems.push(this.items[j].product_sku)
    }

    let MyItemsDict = []
    Object.entries(config.products).map(([key, product]) => {
      if(product.skus.some(v => myItems.includes(v))){
        MyItemsDict = MyItemsDict.concat(product)
      }
    })

    if(this.shippingAndTaxes.iso==="FR"){
      return 0
    }else{
      if((MyItemsDict.filter(x => !x.is_virtual)).length>0){
        return 10
      }else{
        return 0
      }
    }

  }

  getCouponsString () {
    return this.coupons.map(coupon =>  coupon.code).join()
  }

  toDict(waiting=null) {
    return {
      items: this.items,
      coupons: this.coupons,
      shippingAndTaxes: this.shippingAndTaxes,
      waiting: waiting ? true : false,
    }
  }

  getFromCookie() {
    const cookies = new Cookies()
    const cookieCart = cookies.get("cart")
    if (cookieCart) {
      this.items = cookieCart.hasOwnProperty('items') ? cookieCart.items : []
      this.coupons = cookieCart.hasOwnProperty('coupons') ? cookieCart.coupons : []
    }
  }

  addItem (item) {
    this.trackAddToCart(item)
    this.items = Object.values([...this.items, item].reduce((acc, current) => {
      acc[current.product_sku] = {
        product_sku: current.product_sku,
        product_id: this.getProductIdFromSku(current.product_sku),
        quantity: (acc[current.product_sku] ? acc[current.product_sku].quantity : 0) + current.quantity,
        product_name: current.product_name,
        product_price: current.product_price,
      }
      return acc
    }, {}))
  }

  trackAddToCart(item) {
    ReactGA.plugin.execute('ec', 'addProduct', {
      "id": item.product_sku,
      'name': item.product_name,
      'category': "Kits",
      'brand': "Nahibu",
      'variant': "-",
      'price': item.product_price,
      'quantity': item.quantity
    })
    ReactGA.plugin.execute('ec', 'setAction', 'add')
    ReactGA.ga("send", "event", "ecommerce", "add_to_cart", "Add to cart")
  }

  trackRemoveFromCart(item) {
    ReactGA.plugin.execute("ec", "addProduct", {
      "id": item.product_sku,
      'name': item.product_name,
      'category': "Kits",
      'brand': "Nahibu",
      'variant': "-",
      'price': item.product_price,
      'quantity': item.quantity
    })
    ReactGA.plugin.execute('ec', 'setAction', 'remove')
    ReactGA.ga("send", "event", "ecommerce", "remove_from_cart", "Remove from cart")
  }

  trackBeginCheckout() {
    ReactGA.plugin.execute("ec", "setAction", "checkout", {
      step: 1
    })
    ReactGA.ga("send", "event", "ecommerce", "begin_checkout", "Confirm order")
  }

  trackCheckoutProgress() {
    ReactGA.plugin.execute("ec", "setAction", "checkout", {
      step: 2,
      option: "-"
    })
    ReactGA.ga("send", "event", "ecommerce", "checkout_progress", "Pay")
  }

  trackPurchase(orderId, config) {
    const revenue = this.getSubTotal() + this.getShipping(config) - this.getPromo()

    // Google Analytics
    ReactGA.plugin.execute("ec", "setAction", "purchase", {
      id: orderId,
      affiliation: "Nahibu",
      revenue: revenue,
      tax: this.getTaxes(this.getSubTotal() + this.getShipping(config) - this.getPromo()),
      shipping: this.getShipping(config),
      coupon: this.getCouponsString(),
    })

    ReactGA.ga('set', 'currencyCode', 'EUR')
    ReactGA.ga("send", "event", "ecommerce", "purchase", "Purchase")
    ReactGA.plugin.execute('ec', 'clear')

    // Google tag Manager
    window.gtag('event', 'conversion', {
        'send_to': 'AW-725947517/zYNaCImq-v8BEP2olNoC',
        'value': revenue,
        'currency': 'EUR',
        'transaction_id': orderId
    })
  }

  setCoupons (coupons) {
    this.coupons = coupons
  }

  removeCoupon (id) {
    this.coupons = this.coupons.filter(coupon => coupon.id !== id)
  }

  removeCouponByCode (code) {
    this.coupons = this.coupons.filter(coupon => coupon.code !== code)
  }

  isValidCoupon (coupon) {
    return this.items.map(item => {
      return coupon.product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).some(i => i)
  }

  isMaximumAmountRequired (coupon) {
    return coupon.maximum_amount > 0.0 ? this.getSubTotal() < coupon.maximum_amount : true
  }

  isMinimumAmountRequired (coupon) {
    return this.getSubTotal() >= coupon.minimum_amount
  }

  isExcludedCoupon (coupon) {
    return this.items.map(item => {
      return coupon.excluded_product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).some(i => i)
  }

  checkCouponValidity () {
    this.coupons = this.coupons.filter(coupon => {
      if (this.isValidCoupon(coupon) &&
          !this.isExcludedCoupon(coupon) &&
          this.isMinimumAmountRequired(coupon) &&
          this.isMaximumAmountRequired(coupon)
      ) {
        return coupon
      }
      return null
    })
  }

  updateItem (itemSku, quantity) {
    if (quantity === 0) {
      this.removeItem(itemSku)
    } else {
      this.items = this.items.map(item => {
        if (item.product_sku === itemSku) {
          // Track add_to_cart and remove_to_cart
          let newItem = {...item}
          newItem.quantity = Math.abs(quantity - item.quantity)
          if (quantity > item.quantity) {
            this.trackAddToCart(newItem)
          } else {
            this.trackRemoveFromCart(newItem)
          }
          item.quantity = quantity
        }
        return item
      })
    }
  }

  removeItem (itemSku) {
    // track remove_from_cart
    const removedItem = this.items.filter(item => item.product_sku === itemSku)[0]
    this.trackRemoveFromCart(removedItem)
    // Remove
    this.items = this.items.filter(item => item.product_sku !== itemSku)
  }

  getTaxes(price) {
    return price - (price / (1 + this.shippingAndTaxes.tax.rate))
  }

  getSubTotal () {
    const config = window.config
    const probioticsSkus = [
      config.PRODUCT_SKU.HMPROBIOTIC_VITALITY_001,
      config.PRODUCT_SKU.HMPROBIOTIC_COMFORT_001,
      config.PRODUCT_SKU.HMPROBIOTIC_IMMUNITY_001,
      config.PRODUCT_SKU.HMPROBIOTIC_EMOTIONAL_BALANCE_001,
      config.PRODUCT_SKU.HMPROBIOTIC_BAIOTIKU_001
    ]

    const subTotal = this.items.reduce((acc, item) => {
      if (probioticsSkus.includes(item.product_sku)){
        return acc + this.taxWithCountryBaiotiku(item.product_price * item.quantity)
      }else{
        return acc + this.taxWithCountry(item.product_price * item.quantity)
      }
    }, 0)
    return subTotal
  }


  getSubTotalWithoutTax () {
    return this.items.reduce((acc, item) => {
      return acc + (item.product_price * item.quantity)
    }, 0)
  }

  taxWithCountryBaiotiku(price){
    let rate = this.shippingAndTaxes.iso === 'FR' ? 0.055 : 0
    return (price / 1.055) + ((price / 1.055) * rate)
  }

  taxWithCountry(price) {
    return (price / 1.2) + ((price / 1.2) * this.shippingAndTaxes.tax.rate)
  }

  countCartItems() {
    let count = 0
    Object.keys(this.items).forEach(key => {
      count += this.items[key]["quantity"]
    })
    return count
  }

  countCartShippedItems(config) {

    const virtual_products = Object.values(config.products).filter(dict => dict.is_virtual === true);
    let productsVirtualSkus=[]
    Object.entries(virtual_products).map( ([key, value]) => productsVirtualSkus=productsVirtualSkus.concat(value.skus) )

    let count = 0
    Object.keys(this.items).filter(key => !productsVirtualSkus.includes(this.items[key]["product_sku"])).forEach(key => {
      count += this.items[key]["quantity"]
    })
    return count
  }

  getPercentAmount(coupon) {
    const discountDetails = {
      amount: coupon.amount,
      totalAmount: 0,
      totalQuantity: 0,
      amountLabel: 0,
    }

    this.items.filter(item => {
      return coupon.product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).filter(item => {
      return !coupon.excluded_product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).forEach(item => {
      discountDetails.totalAmount += coupon.amount * this.taxWithCountry(item.product_price) * item.quantity / 100
      discountDetails.totalQuantity += item.quantity
      discountDetails.amountLabel = `${coupon.amount}%`
    })

    if (discountDetails.totalAmount === 0) {
      return 0
    } else {
      return discountDetails
    }
  }

  getTotalPromoAmountForFixedProduct(coupon) {
    const discountDetails = {
      amount: coupon.amount,
      totalAmount: 0,
      totalQuantity: 0,
      amountLabel: 0,
    }

    this.items.filter(item => {
      return coupon.product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).filter(item => {
      return !coupon.excluded_product_ids.includes(this.getProductIdFromSku(item.product_sku))
    }).forEach(item => {
      discountDetails.totalAmount += coupon.amount * item.quantity
      discountDetails.totalQuantity += item.quantity
      discountDetails.amountLabel = `${coupon.amount}€ x${discountDetails.totalQuantity}`
    })

    if (discountDetails.totalAmount === 0) {
      return 0
    } else {
      return discountDetails
    }
  }

  getPromo () {
    //let freeShipping = false
    let promoTotal = 0
    this.coupons.forEach(coupon => {
      //freeShipping = coupon.free_shipping ? true : false
      let discountDetails = { amount: coupon.amount }
      if (coupon.discount_type === "percent") {
        discountDetails = this.getPercentAmount(coupon)
      } else if (coupon.discount_type === "fixed_product") {
        discountDetails = this.getTotalPromoAmountForFixedProduct(coupon)
      } else if (coupon.discount_type === "fixed_cart") {
        discountDetails.totalAmount = coupon.amount
      }

      promoTotal += discountDetails.totalAmount
    })
    return promoTotal
  }

  setCookie () {
    const cookies = new Cookies()
    cookies.set('cart', {
      items: this.items,
      coupons: this.coupons,
      timestamp: Date.now(),
    }, { path: '/' });
  }

  containProductId (productSku) {
    return this.items.map(item => item.product_sku).includes(productSku)
  }

  containDistriProducts (config) {
    const distriProducts = Object.values(config.products).filter(dict => dict.is_distri === true);
    let productsDistriSkus=[]
    Object.entries(distriProducts).map( ([key, product]) => productsDistriSkus=productsDistriSkus.concat(product.skus) )
    const found = this.items.map(item => item.product_sku).some(r=> productsDistriSkus.includes(r))
    return found
  }

  containConsult (config) {
    const consultProducts = Object.values(config.products).filter(dict => dict.contain_consult === true);
    let productsConsultSkus=[]
    Object.entries(consultProducts).map( ([key, product]) => productsConsultSkus=productsConsultSkus.concat(product.skus) )
    const found = this.items.map(item => item.product_sku).some(r=> productsConsultSkus.includes(r))
    return found
  }

  getProductIdFromSku (product_sku) {
    if (!this.store) {
      return 0
    }

    const matchingProduct = this.store.find(product => product.product_sku === product_sku)

    if (!matchingProduct) {
      return null
    }

    return matchingProduct.product_id
  }
}
