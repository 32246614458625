import React, { Component } from "react"
import { withTranslation } from 'react-i18next'
import { Helmet } from "react-helmet"


class NahibuHelmet extends Component {

  constructor (props) {
    super(props)
    this.state = {}
  }

  render() {
    //const t = this.props.t
    const robots = this.props.noindex ?
      <meta name="robots" content="noindex, max-snippet:-1, max-image-preview:large, max-video-preview:-1" /> :
      <meta name="robots" content="index, follow, max-snippet:-1, max-image-preview:large, max-video-preview:-1" />

    const canonical = this.props.noindex ?
    "" : <link rel="canonical" href={this.props.canonicalUrl} />
    return(
      <Helmet>
        <title>{this.props.title}</title>
        {canonical}
        <meta name="description" content={this.props.description} />
        {robots}
        <meta property="og:locale" content="fr" />
        <meta property="og:locale:alternate" content="en" />
        <meta property="og:type" content="website" />
        <meta property="og:title" content={this.props.title} />
        <meta property="og:description" content={this.props.description} />
        <meta property="og:url" content="https://nahibu.me/" />
        <meta property="og:site_name" content="Nahibu" />
      </Helmet>
    )
  }
}

export default withTranslation()(NahibuHelmet)
